import {
  ClassicEditor,
  Essentials,
  Bold,
  Italic,
  Paragraph,
  Link,
  List,
  SimpleUploadAdapter,
  Image,
  ImageUpload,
  MediaEmbed,
} from "ckeditor5"
import "ckeditor5/ckeditor5.css"

export default ({
  inputId = null,
  initialValue = "",
  maxLength,
  csrfToken = "",
  configName = "default",
}) => ({
  input: null,
  showRichText: false,
  remaining: maxLength,
  initCkEditor() {
    this.placeholder = this.input.placeholder
    this.showRichText = true
    this.input.classList.add("sr-only")

    const defaultConfig = {
      plugins: [Essentials, Bold, Italic, Link, List, Paragraph],
      toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
      placeholder: this.placeholder,
      initialData: initialValue, // Set initial data
    }

    const mediaConfig = {
      plugins: [
        Essentials,
        Bold,
        Italic,
        Link,
        List,
        Paragraph,
        SimpleUploadAdapter,
        Image,
        ImageUpload,
        MediaEmbed,
      ],
      toolbar: [
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "imageUpload",
        "mediaEmbed",
      ],
      simpleUpload: {
        uploadUrl: "/actions/boosted/dashboard/upload-image",
        headers: { "X-CSRF-TOKEN": csrfToken },
      },
      placeholder: this.placeholder,
      initialData: initialValue, // Set initial data
    }

    const config = {
      default: defaultConfig,
      media: mediaConfig,
    }

    const richTextEditor = this.$refs.richTextEditor

    ClassicEditor.create(richTextEditor as HTMLElement, config[configName])
      .then(editor => {
        editor.model.document.on("change:data", () => {
          const fieldData = editor.getData()
          this.input.value = fieldData
          if (initialValue && inputId) {
            this.$dispatch("input-textarea", {
              value: fieldData,
              id: inputId,
            })
          }
        })

        // todo: add maxLength validation
      })
      .catch(error => {
        console.error(error)
      })
  },
  init() {
    this.input =
      this.$el.querySelector("input") || this.$el.querySelector("textarea")

    if (this.input) {
      const { dataset } = this.input

      if ("projectField" in dataset) {
        if (initialValue && initialValue[dataset.projectField]) {
          this.input.value = initialValue[dataset.projectField]
        }
      }

      if ("richText" in dataset) {
        this.initCkEditor()
      }
    }

    if (maxLength && !this.showRichText) {
      this.input.addEventListener("input", () => {
        this.remaining = maxLength - this.input.value.length
      })
    }
  },
})
